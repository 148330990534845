<template>
  <div
    class="installation-pending"
    :class="[
      status === 'success' ? 'installation-pending--success' : '',
      status === 'error' ? 'installation-pending--error' : '',
    ]"
  >
    <div class="installation-pending__content">
      <div class="installation-pending__background">
        <svg width="74" height="71" viewBox="0 0 74 71" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M3.31077 47.1185C-7.38322 36.9575 9.25187 4.72746 32.8335 0.658479C59.3171 -3.91625 77.8945 16.0379 73.3015 39.0495C68.2287 64.4289 34.9357 77.1186 25.4299 68.1071C19.8545 62.8197 29.1546 56.3139 22.2994 49.8081C15.4443 43.3024 8.315 51.8771 3.33362 47.1185H3.31077Z" fill="#F9F9FB"/>
        </svg>

      </div>

      <InstanceItemInstallationIllustrationError
        v-if="status === 'error'"
        class="installation-pending__img installation-pending__img--error"
      >
      </InstanceItemInstallationIllustrationError>

      <InstanceItemInstallationIllustrationPending
        v-else
        class="installation-pending__img installation-pending__img--pending"
      >
        <v-icon v-if="status === 'success'" color="#fff" size="24"
          >$check</v-icon
        >
        <loader v-else :noMargins="true" :color="'#fff'" :size="12" />
      </InstanceItemInstallationIllustrationPending>

      <div class="installation-pending__text p-4">
        <template v-if="status === 'error'">
          {{ $t('message.status.install.failed') }}. <a class="error--text" @click="$emit('try-again-click')">
          {{ $t('button.try') }}</a>
        </template>
        <template v-else-if="status === 'success'">
          {{ $t('message.status.install.success') }}
        </template>
        <template v-else>{{ $t('message.status.install.inProgress') }}</template>
      </div>
    </div>
  </div>
</template>

<script>
import InstanceItemInstallationIllustrationError from "../illustrations/instance-item-installation-illustration-error.vue";
import InstanceItemInstallationIllustrationPending from "../illustrations/instance-item-installation-illustration-pending.vue";

import Loader from "../Loader.vue";

export default {
  components: {
    Loader,
    InstanceItemInstallationIllustrationError,
    InstanceItemInstallationIllustrationPending,
  },
  props: {
    status: String,
  },
};
</script>

<style lang="scss" scoped>
.installation-pending {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: relative;
  &::before {
    content: "";
    position: absolute;
    z-index: 1;
    // background: var(--v-primary-lighten4);
    opacity: 0.8;
    height: 100%;
    width: 100%;
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    z-index: 2;
  }

  &__background {
    z-index: 0;
    color: var(--v-primary-base);
  }
  &__img {
    position: absolute;
    top: calc(-2px);
    left: calc(50% - 40px);
    z-index: 2;
  }

  &__text {
    color: map-get($text, base);
    a {
      color: map-get($primary, darken1);
      transition: color 0.24s ease;
      &:hover {
        color: map-get($primary, base);
      }
    }
  }

  &--error {
    &::before {
      // background: var(--v-error-lighten5);
    }
    .installation-pending {
      &__background  svg * {
       fill: var(--v-error-lighten5);
       stroke: var(--v-error-lighten5)
      }
    }
  }
}
</style>