var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.$store.state.home.user)?_c('div',[_c('v-overlay',{staticClass:"main-overlay",attrs:{"value":_vm.isAnyOpen,"z-index":"1010"}}),_c('OnboardingImportInstanceSetupCompleteModal',{attrs:{"isOpen":_vm.displayImportInstanceSetupCompleteModal,"importedInstance":_vm.importedInstance,"nameservers":_vm.nameservers},on:{"next":_vm.endInstanceImportProcess}}),_c('OnboardingFtpAccountConfigurationModal',{attrs:{"isOpen":_vm.displayFtpAccountConfigurationModal,"importInstanceData":_vm.importInstanceData},on:{"next":_vm.handleFtpAccountConfigurationNext,"removeModal":_vm.deactivateAll}}),_c('OnboardingImportInstanceUrlSettingsModal',{attrs:{"isOpen":_vm.displayImportInstanceUrlSettingsModal},on:{"next":_vm.handleImportInstanceUrlSettingsNext,"removeModal":_vm.deactivateAll}}),_c('OnboardingChooseCreationTypeModal',{attrs:{"isOpen":_vm.displayChooseCreationTypeModal},on:{"next":_vm.selectInstanceCreationType,"removeModal":_vm.deactivateAll}}),_c('choose-onboarding-process',{attrs:{"isOpen":_vm.displayChooseOnboardingTypeModal},on:{"next":_vm.demoOnboardingSelected,"removeModal":_vm.deactivateAll}}),_c('onboarding-installation-limit-modal',{attrs:{"selectedService":_vm.selectedService,"vars":_vm.limitModalVars,"isOpen":_vm.displayLimitModal},on:{"goBack":_vm.deactivateAll,"removeModal":_vm.deactivateAll}}),_c('onboarding-installation-zero-data-modal',{attrs:{"isOpen":_vm.displayZeroDataModal},on:{"goBack":_vm.deactivateAll,"removeModal":_vm.deactivateAll}}),_c('onboarding-choose-product-modal',{attrs:{"item":_vm.currentlySelectedTheme,"loading":_vm.createSiteSubmitLoading,"services":_vm.availableServices,"selectedService":_vm.selectedService,"isOpen":_vm.displaySelectProductModal
        ? 'selectProduct'
        : _vm.displayWebsiteDomainModal
        ? 'setDomain'
        : _vm.displayWebsiteInstallationModal
        ? 'selectTitle'
        : false},on:{"goBack":_vm.goBack,"removeModal":_vm.deactivateAll,"service-selected":_vm.serviceSelected,"change-theme-button-clicked":_vm.changeThemeButtonClicked,"change-service-button-clicked":_vm.changeServiceButtonClicked,"installWebsite":_vm.installWebsite}}),_c('installation-progress-modal',{attrs:{"theme":_vm.currentlySelectedTheme,"isOpen":_vm.displayInstallationProgress,"completed":_vm.installationCompleted,"error":_vm.installationError,"installedInstance":_vm.installedInstance},on:{"tryAgain":_vm.tryAgain,"gotoDashboard":_vm.gotoDashboard,"removeModal":_vm.deactivateAll}}),_c('onboarding-choose-template-modal',{attrs:{"isOpen":_vm.displayChooseTemplate,"selectedService":_vm.selectedService},on:{"goBack":_vm.gotoDashboard,"theme-selected":_vm.themeSelected,"removeModal":_vm.deactivateAll}}),_c('new-instance-modal',{attrs:{"isOpen":_vm.displayStandardForm,"selectedTheme":_vm.currentlySelectedTheme,"selectedService":_vm.selectedService},on:{"removeModal":_vm.deactivateAll}})],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }