<template>
  <v-dialog
    transition="fade-transition"
    persistent
    ref="dialog"
    v-model="isOpen"
    absolute
    hide-overlay
    :overlay-opacity="0"
  >
    <div class="card-overlay" @click="$refs.dialog.animateClick()" />

    <v-card style="width: 560px" class="" v-if="isOpen">
      <OnboardingLimitReached class="mx-auto mt-8" />

      <v-card-text height="800px" class="px-10 pt-8">
        <h4 class="text-center pb-6">{{ $t('heading.onboarding.noPlan.title') }}</h4>
        <v-btn x-small icon @click="$emit('removeModal')" class="close-model">
          <v-icon>$close</v-icon>
        </v-btn>
        <p class="p-1 text-center">
          {{ $t('heading.onboarding.noPlan.info') }}
        </p>
      </v-card-text>
      <v-card-actions class="d-flex flex-column pb-4 px-10 pt-2">
        <v-btn
          v-if="orderNewServiceLink"
          :href="orderNewServiceLink"
          target="_blank"
          x-large
          elevation="0"
          color="primary"
          block
        >
          {{ $t('button.plan.order') }}
        </v-btn>
        <v-btn
          x-large
          elevation="0"
          class="mx-0 mt-2"
          @click="$emit('goBack')"
          color="gray"
          text
          block
        >
          <span v-if="!orderNewServiceLink" class="p-1 font-weight-light gray--text text--darken-1">
            {{ $t('button.dashboard.back') }}
          </span>
          <span class="p-1 font-weight-light gray--text text--darken-1">
            {{ $t('button.maybeLater') }}
          </span>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import OnboardingLimitReached from "../illustrations/onboarding-limit-reached.vue";

export default {
  components: {
    OnboardingLimitReached,
  },
  props: {
    limit: Number,
    isOpen: Boolean,
  },
  computed: {
    orderNewServiceLink() {
      return this.$store.state.home.user.order_service_link;
    },
  },
  watch: {
    isOpen: function (value) {
      value
        ? this.$store.dispatch("lockBodyScroll")
        : this.$store.dispatch("unlockBodyScroll");
    },
  },
};
</script>

<style lang="scss" scoped>
.v-dialog__content::v-deep {
  .v-dialog {
    overflow-y: auto;
    overflow-x: hidden;
  }
}

.close-model {
  position: absolute;
  top: -40px;
  right: -8px;
}
.v-card::v-deep {
  .v-card__text {
    -webkit-line-clamp: unset !important;
  }
  @media (max-width: 480px) {
    .v-card__text,
    .v-card__actions {
      padding-left: 24px !important;
      padding-right: 24px !important;
    }
  }
}
</style>