<template>
  <div class="custom-sort d-flex align-center">
    <div
      v-for="(item, index) in props.headers"
      :key="index"
      class="sort-item"
      :class="`item--${item.text ? item.text.toLowerCase() : 'select'}`"
    >
      <div class="custom-sort__checkbox" v-if="index === 0">
        <checkbox :checked="allSelected" @change="handleSelectAll" />
      </div>
      <div
        v-else-if="item.text"
        class="custom-sort__item"
        :class="`${item.sortable ? 'custom-sort__item--sortable' : ''}`"
        @click="item.sortable ? handleSortClick(item) : ''"
      >
        {{ item.text }}
        <div class="custom-sort__arrows" v-if="item.sortable">
          <v-icon
            size="12"
            :up="
              !props.options.sortDesc[0] &&
              props.options.sortBy[0] === item.value
            "
            :down="
              props.options.sortDesc[0] &&
              props.options.sortBy[0] === item.value
            "
            class="custom-sort__arrow-up"
            >$arrowsSort</v-icon
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Checkbox from "../buttons/Checkbox.vue";

export default {
  components: {
    Checkbox,
  },
  props: {
    isMobile: Boolean,
    props: Object,
    allSelected: Boolean,
  },
  methods: {
    handleSelectAll() {
      this.$emit("selectAll", !this.allSelected);
    },
    handleSortClick: function (data) {
      if (this.props.options.sortBy[0] === data.value) {
        this.$emit("update:sortDesc", !this.props.options.sortDesc[0]);
      } else {
        this.$emit("update:sortBy", data);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.custom-sort {
  padding: 0 24px;
  height: 56px;
  td:first-child {
    padding-left: 24px;
    padding-right: 0px;
  }
}

.custom-sort__arrows {
  margin-left: 6px;
}

.custom-sort__item {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  color: map-get($text, base);
  font-size: $font-size-sm;
  line-height: $line-height-sm;

  &--sortable {
    cursor: pointer;

    &:hover {
      color: map-get($primary, darken1);
    }
  }
}

.item--select {
  display: flex;
  justify-content: flex-start;
  padding: 0 !important;
}

.item--title {
  padding-left: 8px !important;
}
.v-application--is-rtl{
    .item--title{
        padding-left: 0px !important;
        padding-right: 8px !important;
    }
}
</style>